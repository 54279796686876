import HavalRegular from './Haval-Regular';
import HavalMedium from './Haval-Medium';

const fonts = [
	{
		font: HavalRegular,
		fontStyle: 'normal',
		fontWeight: 'normal',
	},
	{
		font: HavalMedium,
		fontStyle: 'normal',
		fontWeight: '500',
	},
];

const fontName = 'Haval';
const fontsMeta = 'data:application/octet-stream;base64,';


setTimeout(() => {
	fonts.forEach((f) => {
		fetch(`${fontsMeta}${f.font}`)
			.then(resp => resp.arrayBuffer())
			.then(font => {
				const fontFace = new FontFace(fontName, font, {
					weight: f.fontWeight,
					style: f.fontStyle,
				});
				document.fonts.add(fontFace);
			});
	});
});
