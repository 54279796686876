import smoothscroll from 'smoothscroll-polyfill';
import Vue from 'vue';
smoothscroll.polyfill();

import '../scss/main.scss';

import './fonts';


new Vue({
	el: '#haval-hub',
	data: {
		isVideoShown: false,

		isShowCalendarText: window.innerWidth >= 560,
		isShowProjectText: window.innerWidth >= 560,
	},
	methods: {
		scrollTo(id) {
			const target = document.querySelector(id);

			window.scrollTo({
				top: target?.getBoundingClientRect().top + window.pageYOffset,
				behavior: 'smooth',
			});
		},
		showVideo() {
			this.isVideoShown = true;
		},
	},
});
